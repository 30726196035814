  
    <div class="main-content-top">
        <h4 class="option-title">Contact Information</h4>
        <p class="option-content">We’re missing the below contact information in
            your patient profile. Please input the requested data so we can send you
            order updates, receipts, and future communications.</p>
        <div class="lab-dropdown-listing dob-verify-controls contact-label-black">
            <div class="row">
                <div class="col-md-6" *ngIf="!currentCustomer.patientInfo.Patient_Primary_Phone || currentCustomer.patientInfo.Patient_Primary_Phone.trim() === ''">
                    <div class="form-group mb-3 mt-3"> 
                        <label for="" class="form-label">Phone Number</label>
                        <input  type="text"  (keyup)="usNumbermasking($event)" class="form-control" placeholder="Phone Number" [(ngModel)]="phoneNumber" (ngModelChange)="checkEmailPhone()" >
                      </div>
                </div>
                <div class="col-md-6" *ngIf="!currentCustomer.patientInfo.Patient_Email_Address || currentCustomer.patientInfo.Patient_Email_Address.trim() === ''">
                    <div class="form-group mb-3 mt-3"> 
                        <label for="" class="form-label">Email Address</label>
                        <input  type="email" class="form-control" placeholder="Email Address" [(ngModel)]="email" (ngModelChange)="checkEmailPhone()">
                      </div>
                </div>
            </div>
        </div>
    </div>
