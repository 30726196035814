import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer/customer.component';
import { ExternalRoutingModule } from './external-routing.module';
import { DeliveryOptionComponent } from './delivery-option/delivery-option.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ContactLensComponent } from './contact-lens/contact-lens.component';
import { SupplyInfoComponent } from './supply-info/supply-info.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { GooglePayButtonModule } from "@google-pay/button-angular";
import { GooglePayComponent } from './google-pay/google-pay.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    CustomerComponent, 
    DeliveryOptionComponent, 
    PaymentSummaryComponent, 
    PaymentMethodComponent, 
    ContactLensComponent, 
    SupplyInfoComponent,
    HeaderComponent, 
    ContactInformationComponent, 
    GooglePayComponent
  ],
  imports: [
    CommonModule,
    ExternalRoutingModule,
    FormsModule,
    GooglePayButtonModule,
    SharedModule
  ],
})
export class ExternalModule { }