import { Component ,Input,OnInit,EventEmitter,Output} from '@angular/core';
import * as moment from 'moment';
import { ApiServiceBackEnd } from 'src/app/services/apiBackend.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

declare const Stripe: any

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {

  @Input('currentCustomer') currentCustomer:any;
  @Input('divError') divError:any;
  @Output('changeEvent') changeEvent: EventEmitter<any> = new EventEmitter()
  @Output('checkCard') checkCard: EventEmitter<any> = new EventEmitter()

  expiryDate:number = 0;
  expiryMonthsLeft:number=0
  stripe: any;
  card: any
  cardHolderName: string = ''
  errorState:any={};
  receivedData: string="";
  environment = environment;

 
  ngOnInit(): void {
    this.loadScript('stripe', 'https://js.stripe.com/v3/')
    this.expiryDate=this.currentCustomer.Soft_CL_Associated_Rx_Expiration_Date
    const startDate = moment(this.expiryDate, 'MM/DD/YYYY');
    // Get the current date
    const currentDate = moment();
    // Calculate the difference in months
    const monthsDifference = startDate.diff(currentDate, 'months');
    this.expiryMonthsLeft=Number(monthsDifference);

    this.api2.errorState.subscribe(res=>{
      this.errorState=res;
    })

    this.dataService.data$.subscribe(data => {
      this.receivedData = data;
    });
  }

  constructor(
    private api2:ApiServiceBackEnd,
    private dataService: DataService
  ) { }

  loadScript(id: string, src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Resolve if already loaded
      if (window.document.getElementById(id)) {
        // return resolve();
      } else {
        // Load script
        let scriptElement = document.createElement('script');
        scriptElement.id = id;
        scriptElement.type = 'text/javascript';
        scriptElement.src = src;
        scriptElement.onload = () => {
  
          // Assuming this is an Angular component method
          this.initializeStripe();
          
          // resolve();
        };
        scriptElement.onerror = (error: any) => reject(error);
        document.getElementsByTagName('head')[0].appendChild(scriptElement);
      }
    });
  }
  
  initializeStripe(): void {
    this.stripe = Stripe(this.environment.STRIPE_KEY);
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount('#card-element');
    this.card.on('change', (event: { complete: any; error: any; }) => {
      if (event.complete) {
        // Enable payment button
        this.checkCard.emit(event);
      } else if (event.error) {
        this.checkCard.emit(event.error);
        // Show validation to the customer
      }else{
        
      }
    });
  }

  
  async onSubmit() {
    var a={billing_name:this.cardHolderName}
    console.log("this.cardHolderName",this.cardHolderName)
    if(!this.cardHolderName|| this.cardHolderName=="" || this.cardHolderName==undefined){
      console.log("are you there")
      this.changeEvent.emit(a);

    }else{
      this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.cardHolderName,
        },
      })
      .then((result:any)=> {
        console.log("result",result)
        var b=result;
        const combinedSettings = Object.assign({}, a, b);
        this.changeEvent.emit(combinedSettings)
      });
    }

   

    // }
  
  }

 



formatPrice(value:any){
  return (value)
}

calculateDiscountPrice(price:any,percentDiscount:any,quantity:any){
  const discountAmount = (percentDiscount / 100) * price;
  const discountedPrice = price - discountAmount;
   return (discountedPrice * quantity).toFixed(2)

}


}
