<!-- <app-google-pay></app-google-pay> -->
<!-- <app-payment-summary></app-payment-summary> -->
<app-header *ngIf="currentCustomer.enterpriseId" [currentCustomer]="currentCustomer"></app-header>
<div *ngIf="currentCustomer.enterpriseId" class="reorder-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="reorder-progress web-card">
          <ul class="nav nav-pills flex-md-column" role="tablist" *ngIf="currentCustomer.enterpriseId"
            [class.statusAll]="!currentCustomer.patientInfo.Patient_Email_Address || !currentCustomer.patientInfo.Patient_Primary_Phone || currentCustomer.patientInfo.Patient_Email_Address.trim() === '' || currentCustomer.patientInfo.Patient_Primary_Phone.trim() === ''">

            <li style="pointer-events: none;" class="nav-item" [class.active]="activeSection2">
              <a class="nav-link" [class.active]="activeSection2" data-bs-toggle="pill" href="javascript:void(0)">Supply
                Amount</a>
            </li>
            <li style="pointer-events: none;" class="nav-item" *ngIf="currentCustomer.enterpriseId"
              [class.active]="activeSection3">
              <a class="nav-link" *ngIf="currentCustomer.enterpriseId" [class.active]="activeSection3"
                data-bs-toggle="pill" href="#deliveryOption">Delivery Option</a>

            <li
              *ngIf="!currentCustomer.patientInfo.Patient_Email_Address || !currentCustomer.patientInfo.Patient_Primary_Phone || currentCustomer.patientInfo.Patient_Email_Address.trim() === '' || currentCustomer.patientInfo.Patient_Primary_Phone.trim() === ''"
              class="nav-item" [class.active]="activeSection0">
              <a class="nav-link" [class.active]="activeSection0" data-bs-toggle="pill"
                href="javascript:void(0)">Contact Information</a>
            </li>
            <li style="pointer-events: none;" class="nav-item" [class.active]="activeSection4">
              <a class="nav-link" data-bs-toggle="pill" [class.active]="activeSection4" href="#paymentMethod">Payment
                Method</a>
            </li>
          </ul>
          <div *ngIf="errorMsg.length>0" class="error-message d-none d-md-flex align-items-center">
            <img src="./assets/images/order-error-icon.svg" />
            <p class="transaction-content text-danger mb-0">Error came on<strong class="d-block">{{ currentDate |
                date:'MM/dd/yy · h:mm a' }}</strong></p>
          </div>
          <div *ngIf="success" class="error-message  d-none d-md-flex align-items-center">
            <img src="./assets/images/payment-success.svg" />
            <p class="transaction-content mb-0">Purchased on<strong class="d-block">{{ currentDate | date:'MM/dd/yy ·
                h:mm a' }}</strong></p>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div *ngIf="loading">
          <app-loader></app-loader>
        </div>
        <!-- Success Message Wrap -->
        <div *ngIf="success" class="payment-success-modal">
          <div class="success-wrap">
            <div class="modal-main">
              <div class="modal-content justify-content-center">
                <div class="payment-success-content d-flex align-items-center justify-content-center mb-md-4">
                  <img src="./assets/images/payment-success.svg" />
                  <div class="payment-content-wrap">
                    <h3 class="success-title">Payment Successful</h3>
                    <p class="transaction-content mb-1">Thanks for your business, Jared! </p>
                    <p class="transaction-content mb-0">We’ll send a receipt to your email and periodic updates about
                      the status of your order.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Message Wrap -->
        <div *ngIf="errorMsg.length>0" class="payment-success-modal">
          <div class="success-wrap">
            <div class="modal-main">
              <div class="modal-content justify-content-center">
                <div class="payment-success-content d-flex align-items-center justify-content-center mb-md-4">
                  <img src="./assets/images/order-error-icon.svg" />
                  <div class="payment-content-wrap">
                    <h3 class="success-title">Order Error</h3>
                    <p class="transaction-content mb-0">Please address the below errors and resubmit your order.</p>
                    <p (click)="scrollToDiv(divError)" class="transaction-content text-danger mb-0">
                      <strong>{{errorMsg}}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainSection" [class.disabled]="success" *ngIf="currentCustomer.enterpriseId">

          <app-contact-lens id="section1" *ngIf="currentCustomer.enterpriseId"
            [currentCustomer]="currentCustomer"></app-contact-lens>

          <div class="web-card" [class.invalid-payment]="divError=='section2'">
            <app-supply-info id="section2" *ngIf="currentCustomer.enterpriseId" [currentCustomer]="currentCustomer"
              (changeEvent)="changeInSupply($event)" [totalPrice]="totalPrice"></app-supply-info>

          </div>
          <div class="web-card" [class.invalid-payment]="divError=='section3'">
            <app-delivery-option id="section3" *ngIf="currentCustomer.enterpriseId"
              (changeEvent)="changeInDeliveryOption($event);" (addressChange)="changeInDeliveryAddress($event);"
              [currentCustomer]="currentCustomer">
            </app-delivery-option>
          </div>
          <div class="web-card"
            *ngIf="!currentCustomer.patientInfo.Patient_Email_Address || !currentCustomer.patientInfo.Patient_Primary_Phone || currentCustomer.patientInfo.Patient_Email_Address.trim() === '' || currentCustomer.patientInfo.Patient_Primary_Phone.trim() === ''"
            [class.invalid-payment]="divError === 'section0'">
            <app-contact-information id="section0" [currentCustomer]="currentCustomer"
              (changeInformation)="changeInformation($event)"></app-contact-information>
          </div>
          <app-payment-method id="section4" *ngIf="currentCustomer.enterpriseId" [divError]="divError"
            (changeEvent)="cardTokenDetail($event)" (checkCard)="checkValidCard($event)"
            [currentCustomer]="currentCustomer">
          </app-payment-method>
        </div>
      </div>
    </div>
  </div>
</div>


<button #successOpenBtn type="button" data-bs-toggle="modal" data-bs-target="#myModal" style="display: none;"></button>

<div class="modal payment-success-modal verify-dob-modal overlay-dark" id="myModal" data-bs-backdrop="static"
  style="backdrop-filter: blur(5px);">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="save-title text-center">Patient Verification</h4>
        <button #successCloseBtn type="button" style="display: none;" class="btn-close"
          data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="payment-success-content text-center">
          <div class="form-group mb-3">
            <input type="text" class="form-control" placeholder="MM/DD/YYYY" [(ngModel)]="inputDOB" value="inputDOB"
              (ngModelChange)="formatInputDOB()" maxlength="10">
            <div *ngIf="dobError" class="text-danger">Please enter a valid date in MM/DD/YYYY format.</div>

          </div>
          <p *ngIf="currentCustomer.enterpriseId" class="transaction-content">
            {{currentCustomer.patientInfo.Patient_First_Name}}, please verify your date of birth to proceed
            with ordering contact lenses.</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button (click)="onSubmit()" class="btn-primary btn">Verify</button>
      </div>
    </div>
  </div>
</div>


<!--Expiry popup -->
<button #successOpenExpiryBtn type="button" data-bs-toggle="modal" data-bs-target="#expiryModal"
  style="display: none;"></button>

<div class="modal payment-success-modal verify-dob-modal overlay-dark" id="expiryModal" data-bs-backdrop="static"
  style="backdrop-filter: blur(5px);">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="save-title text-center">Prescription Expired</h4>
        <button #successCloseBtn type="button" style="display: none;" class="btn-close"
          data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="payment-success-content text-center">
          <div class="customer-content text-center">
            <img src="./assets/images/order-error-icon.svg" class="mb-3" alt="" style="max-width: 320px" />
            <p class="text-danger"><strong>Expired on:</strong> {{currentCustomer.Soft_CL_Associated_Rx_Expiration_Date|
              date: 'MM/dd/yy' }}</p>
            <p class="text-center">Your prescription is expired. We are unable, legally, to assist you in
              placing an order until you renew your prescription. Please schedule an
              exam by using our online scheduler or calling us for assistance. We look
              forward to seeing you soon!</p>
            <a class="btn-primary btn mx-2 mt-3 mt-md-4 d-inline-flex align-items-center"
              href="mailto:{{currentCustomer?.storesInfo?.email}}"> <img src="./assets/images/globe-icon.png"
                style="max-width: 18px;filter: invert(1);" class="me-2" alt="" /><span>Schedule Online</span></a>
            <a class="btn-primary btn mx-2 mt-3 mt-md-4 d-inline-flex align-items-center"
              href="tel:{{currentCustomer?.storesInfo?.phoneNumber}}"><img src="./assets/images/call-white-icon.png"
                style="max-width: 18px;filter: invert(1);" class="me-2" alt="" /><span>Call Us</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>