import { Component,Input,OnInit,EventEmitter ,Output} from '@angular/core';
import {usStatesList} from '../../helpers/states'
@Component({
  selector: 'app-delivery-option',
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.css']
})
export class DeliveryOptionComponent implements OnInit{
  @Input('currentCustomer') currentCustomer:any;
  @Output('changeEvent') changeEvent: EventEmitter<any> = new EventEmitter()
  @Output('addressChange') addressChange: EventEmitter<any> = new EventEmitter()

  homeAddress:string=""
  selectedOption: string = '';
  address1:any;
  address2:any;
  state:any;
  city:any;
  zipcode:any;
  deliveryLocation:string=""
  statesArray:any
  
  

  ngOnInit(): void {
    this.statesArray=usStatesList
   this.homeAddress=this.currentCustomer.patientInfo.Patient_Address_1 + this.currentCustomer.patientInfo.Patient_Address_2 + this.currentCustomer.patientInfo.Patient_City +" "+ this.currentCustomer.patientInfo.Patient_State+" "+ this.currentCustomer.patientInfo.Patient_Zip_Code
  this.address1=this.currentCustomer.patientInfo.Patient_Address_1
  this.address2=this.currentCustomer.patientInfo.Patient_Address_2
  this.city=this.currentCustomer.patientInfo.Patient_City
  this.state=this.currentCustomer.patientInfo.Patient_State
  this.zipcode=this.currentCustomer.patientInfo.Patient_Zip_Code
  if(!this.currentCustomer.patientReorderSetting.shipToOffice){
    this.deliveryLocation='home'
  }
  }

  onSelectionChange() {
    this.changeEvent.emit(this.selectedOption)
  }
  onSelectionDeliveryLocation(){
    if(this.deliveryLocation == 'office'){
      this.selectedOption="standard"
      this.changeEvent.emit(this.selectedOption)
    }
    else if(this.deliveryLocation == 'home'){
      this.selectedOption=" "
    }
    else{

    }
  
    var updatedHomeAdress={
      "address1":this.address1,
      "address2":this.address2,
      "city":this.city,
      "state":this.state,
      "zipCode":this.zipcode
    }
    var data={
      "location":this.deliveryLocation?this.deliveryLocation:'home',
      "updatedHomeAddress":updatedHomeAdress
    }
    this.addressChange.emit(data);
       
  }
  setDeliveryAddress(value:any){
  this.deliveryLocation=value

 
  }
  limitToFiveDigits() {
    const input = this.zipcode.toString();
    if (input.length > 5) {
      this.zipcode = parseInt(input.slice(0, 5), 10);
    }
  }
  formatPrice(value:any){
    return parseFloat(value).toFixed(2)
  }
}
