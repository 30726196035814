import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-supply-info',
  templateUrl: './supply-info.component.html',
  styleUrls: ['./supply-info.component.css']
})

export class SupplyInfoComponent implements OnInit {


  constructor(private dataService: DataService){

  }
  @Input('currentCustomer') currentCustomer: any;
  @Input('totalPrice') totalPrice: any;

  
  leftEyeQuantity: number = 0;
  rightEyeQuantity: number = 0;
  expiryDate: number = 0;
  expiryMonthsLeft: number = 0
  eyeData: any;
  supplyMonthCal: any;
  backendLeftEye: any;
  backendRightEye: any;
  supply3MonthLeft: number = 1;
  supply3MonthRight: number = 1;

  supply6MonthLeft: number = 1;
  supply6MonthRight: number = 1;

  supply12MonthLeft: number = 1;
  supply12MonthRight: number = 1;
  originalPrice:any



  @Output('changeEvent') changeEvent: EventEmitter<any> = new EventEmitter()

  ngOnInit(): void {
    this.expiryDate = this.currentCustomer.Soft_CL_Associated_Rx_Expiration_Date
    // const startDate = moment(this.expiryDate, 'MM/DD/YYYY');
    // console.log("startDate",startDate)
    // // Get the current date
    // const currentDate = moment();
    // console.log("currentDate",currentDate)
    // // Calculate the difference in months
    // const monthsDifference = startDate.diff(currentDate, 'months');
    // console.log("monthsDifference",monthsDifference)
    // this.expiryMonthsLeft = monthsDifference
    // console.log(" this.expiryMonthsLeft",this.expiryMonthsLeft)
    let ExpirationDate = this.currentCustomer.Soft_CL_Associated_Rx_Expiration_Date;
    let monthsLeft = moment(ExpirationDate, "MM/DD/YYYY").diff(
      moment(),
      "months"
    );
    this.expiryMonthsLeft = monthsLeft
    // let isExpired = false;
    // if(moment(ExpirationDate, "MM/DD/YYYY").isBefore(moment())){
    //   isExpired = true;
    // }
    console.log("monthsLeft",monthsLeft)
    this.calculateMaxQuantity()
  }

  

  calculateMaxQuantity() {
    if (this.expiryMonthsLeft > 3 && this.expiryMonthsLeft < 6) {
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_3_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_3_Month
    }
    else if (this.expiryMonthsLeft > 5 && this.expiryMonthsLeft < 12) {
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_6_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_6_Month
    }
    else if (this.expiryMonthsLeft > 11) {
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_12_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_12_Month
    }
  }
  increment(eye: string) {
    this.supply3MonthLeft = this.currentCustomer.leftEyeData.Quantity_3_Month
    this.supply3MonthRight = this.currentCustomer.rightEyeData.Quantity_3_Month

    this.supply6MonthLeft = this.currentCustomer.leftEyeData.Quantity_6_Month
    this.supply6MonthRight = this.currentCustomer.rightEyeData.Quantity_6_Month

    this.supply12MonthLeft = this.currentCustomer.leftEyeData.Quantity_12_Month
    this.supply12MonthRight = this.currentCustomer.rightEyeData.Quantity_12_Month

    if (eye === 'left') {

      if (this.leftEyeQuantity == this.backendLeftEye) {
        alert("Cannot Increase Quantity")
      } else {
        this.leftEyeQuantity++;

      }

    } else if (eye === 'right') {
      if (this.rightEyeQuantity == this.backendRightEye) {
        alert("Cannot Increase Quantity")
      } else {
        this.rightEyeQuantity++;
      }

    }
    if(this.supply12MonthLeft==this.leftEyeQuantity || this.supply12MonthRight==this.rightEyeQuantity){
      this.eyeData = {
        rightEyeQuantity: this.rightEyeQuantity,
        leftEyeQuantity: this.leftEyeQuantity,
        supplyMonth:'12 months'
  
      }
    }else{
      this.eyeData = {
        rightEyeQuantity: this.rightEyeQuantity,
        leftEyeQuantity: this.leftEyeQuantity,
        supplyMonth: this.supplyMonthCal
  
      }
    }
    this.changeEvent.emit(this.eyeData)
  }
  get maxSupply(): number {
    return Math.max(this.supply3MonthLeft, this.supply3MonthRight);
  }

  decrement(eye: string) {
    this.supply3MonthLeft = this.currentCustomer.leftEyeData.Quantity_3_Month
    this.supply3MonthRight = this.currentCustomer.rightEyeData.Quantity_3_Month

    this.supply6MonthLeft = this.currentCustomer.leftEyeData.Quantity_6_Month
    this.supply6MonthRight = this.currentCustomer.rightEyeData.Quantity_6_Month

    this.supply12MonthLeft = this.currentCustomer.leftEyeData.Quantity_12_Month
    this.supply12MonthRight = this.currentCustomer.rightEyeData.Quantity_12_Month

    if (eye === 'left' && this.leftEyeQuantity > 0) {
      this.leftEyeQuantity--;
    } else if (eye === 'right' && this.rightEyeQuantity > 0) {
      this.rightEyeQuantity--;
    }

    if(this.supply12MonthLeft==this.leftEyeQuantity || this.supply12MonthRight==this.rightEyeQuantity){
      this.eyeData = {
        rightEyeQuantity: this.rightEyeQuantity,
        leftEyeQuantity: this.leftEyeQuantity,
        supplyMonth:'12 months'
  
      }
    }else{
      this.eyeData = {
        rightEyeQuantity: this.rightEyeQuantity,
        leftEyeQuantity: this.leftEyeQuantity,
        supplyMonth: this.supplyMonthCal
  
      }
    }
    
    this.changeEvent.emit(this.eyeData)
  }
  supplyMonth(value: string) {
    console.log(">>>>>>>",value)
    this.supplyMonthCal = value
    if (this.supplyMonthCal == "3 months") {
      this.leftEyeQuantity = this.currentCustomer.leftEyeData.Quantity_3_Month
      this.rightEyeQuantity = this.currentCustomer.rightEyeData.Quantity_3_Month
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_3_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_3_Month
    }
    else if (this.supplyMonthCal == "6 months") {
      this.leftEyeQuantity = this.currentCustomer.leftEyeData.Quantity_6_Month
      this.rightEyeQuantity = this.currentCustomer.rightEyeData.Quantity_6_Month
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_6_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_6_Month
    }
    else if (this.supplyMonthCal == "12 months") {
      this.leftEyeQuantity = this.currentCustomer.leftEyeData.Quantity_12_Month
      this.rightEyeQuantity = this.currentCustomer.rightEyeData.Quantity_12_Month
      this.backendLeftEye = this.currentCustomer.leftEyeData.Quantity_12_Month
      this.backendRightEye = this.currentCustomer.rightEyeData.Quantity_12_Month
    }
    else {

    }
    this.eyeData = {
      rightEyeQuantity: this.rightEyeQuantity,
      leftEyeQuantity: this.leftEyeQuantity,
      supplyMonth: this.supplyMonthCal
    }
    this.changeEvent.emit(this.eyeData)
    this.dataService.setData(this.supplyMonthCal);

  }

  formatPrice(value:any){
    // return parseFloat(value.toFixed(2))
    return value


  }
  formatPrice2(value:any){
   
  var a= parseFloat(value)
  return a.toFixed(2)
  }

  formalOriginal(value:any){
   var a= parseFloat(value)
   return a.toFixed(2)
  }
  calculateDiscountPrice(price:any,percentDiscount:any,type:any,quantity:any){
    console.log("price",price)
    console.log("percentDiscount",percentDiscount)
    const discountAmount = (percentDiscount / 100) * price;
    const discountedPrice = price - discountAmount;
    console.log("discountedPrice",discountedPrice)
    console.log("discountAmount",discountAmount)
    if(type=='discountPrice'){
      return discountedPrice.toFixed(2)
    }
    else if(type=='discountAmount'){
      return (discountAmount * quantity).toFixed(2)

    }
    else if(type=='totalPrice'){
      return (discountedPrice * quantity).toFixed(2)

    }
    else {
      console.error("Invalid type. Please provide 'discountPrice' or 'discountAmount'");
      // Return a default value or throw an error based on your needs
      return null; // or throw new Error("Invalid type");
  }

  }

  calculateDiscountPrice2(price:any,percentDiscount:any,quantity:any){
    console.log("price",price)
    console.log("percentDiscount",percentDiscount)
    const discountAmount = (percentDiscount / 100) * price;
    const discountedPrice = price - discountAmount;
        return (discountedPrice * quantity).toFixed(2)
  
  }
  


}
