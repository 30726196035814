
<!-- <div  class="web-card"> -->
    <div class="option-card">
        <h4 class="option-title">Supply Amount</h4>
        <p class="option-content">Please choose how many month's worth of contact lenses you’d like – we’ll handle selecting the right number of boxes for you!</p>
        <div class="common-tabs">
            <!-- Nav tabs -->

            <ul *ngIf="currentCustomer?.patientReorderSetting?.supplyConstraints" class="nav nav-tabs d-flex justify-content-between" role="tablist">
                <li *ngIf="expiryMonthsLeft>2" class="nav-item" (click)="supplyMonth('3 months')">
                  <a  *ngIf="leftEyeQuantity>rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">3 Month <small>Supply</small></a>

                <a *ngIf="leftEyeQuantity<rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">3 Month <small>Supply</small></a>
                  <a *ngIf="leftEyeQuantity==rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">3 Month <small>Supply</small></a>

                  <!-- <span class="save-flag">save $100</span> -->
                </li>
                <li *ngIf="expiryMonthsLeft>5" class="nav-item" (click)="supplyMonth('6 months')">
                  <a *ngIf="leftEyeQuantity>rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">6 Month <small>Supply</small> </a>
                    <a *ngIf="leftEyeQuantity<rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">6 Month <small>Supply</small> </a>
                  <a  *ngIf="leftEyeQuantity==rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">6 Month <small>Supply</small> </a>
                    <!-- <span class="save-flag">save $100</span> -->
               
                </li>
                <li *ngIf="expiryMonthsLeft<12" class="nav-item">
                <a class="nav-link disabled" data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small>
                  <span *ngIf="currentCustomer.patientReorderSetting.annualSupplyDiscount>0" class="save-flag">save ${{formatPrice(currentCustomer.patientReorderSetting.annualSupplyDiscount)}}</span>
                </a>
                </li>
                <li (click)="supplyMonth('12 months')" *ngIf="expiryMonthsLeft==12 || expiryMonthsLeft>12" class="nav-item">
                  <a *ngIf="leftEyeQuantity>rightEyeQuantity" class="nav-link"   data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small> </a>
                  <a *ngIf="leftEyeQuantity<rightEyeQuantity" class="nav-link"   data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small> </a>
                  <a  *ngIf="leftEyeQuantity==rightEyeQuantity" class="nav-link"  data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small>
                    <span *ngIf="currentCustomer.paymentSummary.annualSupplyDiscount>0" class="save-flag">save ${{formatPrice(currentCustomer.paymentSummary.annualSupplyDiscount)}}</span>

                  </a>
                
                
                </li>



            </ul>


            <ul *ngIf="!currentCustomer?.patientReorderSetting?.supplyConstraints" class="nav nav-tabs d-flex justify-content-between" role="tablist">
              <li  class="nav-item" (click)="supplyMonth('3 months')">

                <a class="nav-link"  data-bs-toggle="tab" href="#menu1">3 Month <small>Supply</small></a>

                <!-- <span class="save-flag">save $100</span> -->
              </li>
              <li class="nav-item" (click)="supplyMonth('6 months')">
                <a  class="nav-link"  data-bs-toggle="tab" href="#menu1">6 Month <small>Supply</small> </a>
                  <!-- <span class="save-flag">save $100</span> -->
             
              </li>
             
              <!-- <li  class="nav-item">
                <a class="nav-link disabled" data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small>
                  <span *ngIf="currentCustomer.patientReorderSetting.annualSupplyDiscount>0" class="save-flag">save ${{formatPrice(currentCustomer.patientReorderSetting.annualSupplyDiscount)}}</span>
                </a>
                </li> -->
                <li (click)="supplyMonth('12 months')" class="nav-item">
                  <!-- <a *ngIf="leftEyeQuantity>rightEyeQuantity" class="nav-link"   data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small> </a> -->
                  <!-- <a *ngIf="leftEyeQuantity<rightEyeQuantity" class="nav-link"   data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small> </a> -->
                  <a  class="nav-link"  data-bs-toggle="tab" href="#menu1">12 Month <small>Supply</small>
                    <span *ngIf="currentCustomer.paymentSummary.annualSupplyDiscount>0" class="save-flag">save ${{formatPrice(currentCustomer.paymentSummary.annualSupplyDiscount)}}</span>

                  </a>
                
                
                </li>




          </ul>


            <!-- Tab panes -->
            <div class="tab-content" *ngIf="supplyMonthCal">
                <div id="menu1" class="tab-pane active"><br>
                <div class="common-tabs-content">
                    <div class="form-content">
                      <!-- For 12 months -->
                        <div class="order-detailing" *ngIf="supplyMonthCal=='12 months' && currentCustomer.patientReorderSetting?.annualSupplyDiscount>0 &&  currentCustomer.patientReorderSetting?.annualSupplyDiscountStatus">
                          <div class="row">
                            <div class="col-md-6">
                              <p>LEFT Eye (OS)</p>
                              <ul>
                                <li><span>Quantity of boxes</span><span>{{ leftEyeQuantity }}</span></li>
                                <li>
                                  <span>Price per box</span>
                                  <span>
                                        ${{ formatPrice2(currentCustomer?.paymentSummary?.leftEyeArrellioPrice)}}
                                  </span>
                              </li>
                             
                                <li class="price"><strong>Left Eye Price</strong>
                                  <strong>
                                    <!-- ${{ calculateDiscountPrice(currentCustomer?.paymentSummary?.leftEyeArrellioPrice, currentCustomer.patientReorderSetting?.annualSupplyDiscount, 'totalPrice',leftEyeQuantity) }} -->
                                  
                                      <span>
                                          <del class="text-muted me-2">
                                      ${{ formatPrice2(currentCustomer.paymentSummary?.leftEyePrice) }}
                                      </del>
                                      ${{calculateDiscountPrice2(currentCustomer.paymentSummary?.leftEyeArrellioPrice,currentCustomer.patientReorderSetting?.annualSupplyDiscount,currentCustomer.leftEyeData.Quantity_12_Month)}}
                                      </span>
                                  
                                  </strong>
                                </li>
                                <li class="text-danger">
                                  
                                  <span><strong>Annual Supply Discount</strong></span>
                                  <span> ${{ calculateDiscountPrice(currentCustomer?.paymentSummary?.leftEyeArrellioPrice, currentCustomer.patientReorderSetting?.annualSupplyDiscount, 'discountAmount',currentCustomer.leftEyeData.Quantity_12_Month) }}
                                    ({{formalOriginal(currentCustomer.patientReorderSetting?.annualSupplyDiscount)}}%)
                                  </span>
                                  </li>
                              </ul>
                            </div>
                            <div class="col-md-6">
                              <p>RIGHT Eye (OD)</p>
                              <ul>
                                <li><span>Quantity of boxes</span><span>{{ rightEyeQuantity }}</span></li>
                                <li><span>Price per box</span><span>
                                    ${{formatPrice2(currentCustomer?.paymentSummary?.rightEyeArrellioPrice)}}
                                </span></li>
                                <li class="price"><strong>Right Eye Price</strong><strong>      
                                  <!-- ${{calculateDiscountPrice(currentCustomer?.paymentSummary?.rightEyeArrellioPrice,currentCustomer.patientReorderSetting?.annualSupplyDiscount,'totalPrice',rightEyeQuantity)}} -->
                                 
                                    <span>
                                        <del class="text-muted me-2">
                                    ${{ formatPrice2(currentCustomer.paymentSummary?.rightEyePrice) }}
                                    </del>
                                    ${{calculateDiscountPrice2(currentCustomer.paymentSummary?.rightEyeArrellioPrice,currentCustomer.patientReorderSetting?.annualSupplyDiscount,currentCustomer.rightEyeData.Quantity_12_Month)}}
                                    </span>
                               
                               
                                </strong></li>
                                <li class="text-danger" >
                                  <span><strong>Annual Supply Discount</strong></span>                                  
                                  <span>
                                    ${{calculateDiscountPrice(currentCustomer?.paymentSummary?.rightEyeArrellioPrice,currentCustomer.patientReorderSetting?.annualSupplyDiscount,'discountAmount',currentCustomer.rightEyeData.Quantity_12_Month)}} 
                                  ({{formalOriginal(currentCustomer.patientReorderSetting?.annualSupplyDiscount)}}%)</span></li>
                              </ul>
                            </div>
                          </div>
                        </div>
<!-- other case -->
                        <div class="order-detailing" *ngIf="supplyMonthCal !=='12 months' || !currentCustomer.patientReorderSetting?.annualSupplyDiscountStatus || !(currentCustomer.patientReorderSetting.annualSupplyDiscount > 0)" >
                          <div class="row">
                            <div class="col-md-6">
                              <p>LEFT Eye (OS)</p>
                              <ul>
                                <li><span>Quantity of boxes</span><span>{{ leftEyeQuantity }}</span></li>
                                <li>
                                  <span>Price per box</span>
                                  <span>
                                        ${{ formatPrice2(currentCustomer?.paymentSummary?.leftEyeArrellioPrice)}}
                                  </span>
                              </li>
                             
                    
                                <li class="price"><strong>Left Eye Price</strong>
                                  <strong>                                      
                                      ${{ formatPrice2(currentCustomer?.paymentSummary?.leftEyePrice)}}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-6">
                              <p>RIGHT Eye (OD)</p>
                              <ul>
                                <li><span>Quantity of boxes</span><span>{{ rightEyeQuantity }}</span></li>
                                <li><span>Price per box</span><span>
                                  ${{formatPrice2(currentCustomer?.paymentSummary?.rightEyeArrellioPrice)}}
                                </span></li>
                                <li class="price"><strong>Right Eye Price</strong><strong>      
                                  ${{formatPrice2(currentCustomer?.paymentSummary?.rightEyePrice)}}
                                 
                                </strong></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> 
                </div> 
                </div>
            </div>
        </div>
    <!-- </div> -->