<div class="row payment-method-row">
    <div class="col-md-6">
        <div class="web-card payment-method-card">
            <div class="option-card summary-card">
                <h4 class="option-title">Payment Summary</h4>
                <p class="option-content">Please review the pricing summary below and provide us with card information for payment processing.</p>
                <div class="total-pay-summary d-flex justify-content-between align-items-center">
                    <span>Total Due Today</span>
                    <strong *ngIf="receivedData">${{ currentCustomer.paymentSummary?.Total_Due_Today }}</strong>
                    <strong *ngIf="!receivedData">$0.00</strong>

                </div>
                <div class="delivery-content">
                    <div class="grey-wrap" *ngIf="receivedData">
                        <div class="row">
                            <div class="col-12">
                                 <div  *ngIf="receivedData !=='12 months' || !currentCustomer.patientReorderSetting?.annualSupplyDiscountStatus || !(currentCustomer.patientReorderSetting.annualSupplyDiscount > 0) ">
                                    <div class="form-check-wrap ps-0">
                                        <div *ngIf="currentCustomer.paymentSummary?.leftEyePrice>0" class="form-check-label" for="radio1"><small>Left Eye Price</small><small>${{ formatPrice(currentCustomer.paymentSummary?.leftEyePrice) }}</small></div>
                                    </div>
                                    <div class="form-check-wrap ps-0">
                                        <div *ngIf="currentCustomer.paymentSummary?.rightEyePrice>0" class="form-check-label" for="radio1"><small>Right Eye Price</small><small>${{ formatPrice(currentCustomer.paymentSummary?.rightEyePrice) }}</small></div>
                                    </div>
                                 </div>

                                 <div *ngIf="receivedData=='12 months' && currentCustomer.patientReorderSetting?.annualSupplyDiscount>0 &&  currentCustomer.patientReorderSetting?.annualSupplyDiscountStatus">
                                 <div class="form-check-wrap ps-0">
                                     <div *ngIf="currentCustomer.paymentSummary?.leftEyePrice>0" class="form-check-label" for="radio1"><small>Left Eye Price</small>
                                        <small>
                                            <span>
                                                <del class="text-muted me-2">
                                            ${{ formatPrice(currentCustomer.paymentSummary?.leftEyePrice) }}
                                        </del>
                                            ${{calculateDiscountPrice(currentCustomer.paymentSummary?.leftEyeArrellioPrice, currentCustomer.patientReorderSetting?.annualSupplyDiscount,currentCustomer.leftEyeData.Quantity_12_Month)}}
                                        </span>
                                        </small>
                                    </div>
                                 </div>
                                 <div class="form-check-wrap ps-0">


                                     <div *ngIf="currentCustomer.paymentSummary?.rightEyePrice>0" class="form-check-label" for="radio1">
                                        <small>Right Eye Price</small>
                                        <small>
                                            <span>
                                                <del class="text-muted me-2">
                                            ${{ formatPrice(currentCustomer.paymentSummary?.rightEyePrice) }}
                                            </del>
                                            ${{calculateDiscountPrice(currentCustomer.paymentSummary?.rightEyeArrellioPrice,currentCustomer.patientReorderSetting?.annualSupplyDiscount,currentCustomer.rightEyeData.Quantity_12_Month)}}
                                            </span>
                                        </small>
                                    </div>
                                 </div>
                              </div>

                                <div class="form-check-wrap ps-0" *ngIf="currentCustomer.paymentSummary?.Home_Delivery_Fees>0">
                                    <div  class="form-check-label" for="radio2"><small>Shipping Fees</small><small>${{ formatPrice(currentCustomer.paymentSummary?.Home_Delivery_Fees) }}</small></div>
                                </div>

                                <div class="form-check-wrap ps-0"  *ngIf="currentCustomer.paymentSummary?.Credit_Card_Processing_Fees>0">
                                    <div class="form-check-label" for="radio2"><small>Payment Processor Fees</small><small>${{ formatPrice(currentCustomer.paymentSummary?.Credit_Card_Processing_Fees) }}</small></div>
                                </div>

                                <div class="form-check-wrap ps-0" *ngIf="currentCustomer.paymentSummary?.tax>0">
                                    <div  class="form-check-label" for="radio3"><small>Tax</small><small>${{formatPrice(currentCustomer.paymentSummary?.tax)}}</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="web-card" [class.invalid-payment]="errorState.type=='section4'">
            <div class="option-card summary-card">
                <h4 class="option-title">Payment Method</h4>
                <img class="mt-3" src="assets/images/payment-types.png" alt="Payment Methods" />
                <div class="delivery-content">
                    <div class="grey-wrap bg-white">
                        <div class="form-content">
                            <form>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-3">
                                            <label for="" class="form-label">Card Holder Name</label>
                                            <input type="text" class="form-control" [class.is-invalid]="errorState.subType=='section4_name'" [(ngModel)]="cardHolderName" name="cardHolderName">
                                          </div>
                                          
                                       
                                    </div>
                                    <div>
                                        <label for="card-element">Card Details</label>

                                        <div [class.is-invalid]="errorState.subType=='section4Number'" id="card-element"></div>
                                        <div  id="card-errors"></div>
                                      </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 text-end">
        <div class="form-btn text-end">
            <div class="order-wrap">
                <button (click)="onSubmit()"  class="order-btn btn">place order</button>
            </div>
        </div>
    </div>
</div>