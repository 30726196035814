import { Component ,Input,OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  @Input('currentCustomer') currentCustomer: any;
  brandColor:any;
  buisnessLogo:any;

  ngOnInit(): void {
    this.buisnessLogo=this.currentCustomer.storesInfo.businessLogo.original
    this.brandColor=this.currentCustomer.storesInfo.brandColor
  }

}
