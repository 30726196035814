import { Component,Input,OnInit} from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-contact-lens',
  templateUrl: './contact-lens.component.html',
  styleUrls: ['./contact-lens.component.css']
})
export class ContactLensComponent implements OnInit{
  formattedDate: string = '';
  @Input('currentCustomer') currentCustomer:any;
 

  ngOnInit(): void {
    this.formatDate()
   
  }

  formatDate() {
    // Parse the original date and format it
    const parsedDate = moment(this.currentCustomer.Soft_CL_Associated_Rx_Expiration_Date, 'M/D/YYYY');
    this.formattedDate = parsedDate.format('MMMM DD, YYYY');
  }
}
