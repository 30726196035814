import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  formatDateString(input: string): string {
    if (!input) {
      return '';
    }

    const cleanedInput = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (cleanedInput.length >= 8) {
      // Format the full date (MM/DD/YYYY), limit the year to four characters
      return (
        cleanedInput.slice(0, 2) + '/' + cleanedInput.slice(2, 4) + '/' + cleanedInput.slice(4, 8)
      );
    } else if (cleanedInput.length >= 4) {
      // Format the month and day (MM/DD)
      return cleanedInput.slice(0, 2) + '/' + cleanedInput.slice(2, 4);
    } else if (cleanedInput.length >= 2) {
      // Format the month (MM)
      return cleanedInput.slice(0, 2);
    }

    return cleanedInput.slice(0, 4); // Keep only the first four characters
  }
}
