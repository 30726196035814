// Shared Service
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSubject = new BehaviorSubject<string>('');
  public data$ = this.dataSubject.asObservable();

  private shippingSpeedSubject = new BehaviorSubject<string>('');
  public shippingSpeed$ = this.shippingSpeedSubject.asObservable();

  setData(data: string) {
    this.dataSubject.next(data);
  }

  setShippingSpeed(data:string){
    this.shippingSpeedSubject.next(data);
  }

}
