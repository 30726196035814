import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiEndpoint: string = "";

  user: any;
  private loginSource = new BehaviorSubject<any>({});
  user$ = this.loginSource.asObservable();

  // Show and hide Loader
  showLoader: boolean = true;
  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();
  loader(value: boolean) { this.loaderSubject.next(value); }

  enterpriseId: any;
  brandColor:string='';
  
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.apiEndpoint = environment.API_END_POINT;
  }

  getData<T>(url: string, param?: {}): Observable<T> {
    return this.http.get<T>(this.apiEndpoint + url, { params: param }).pipe(
      catchError(this.errorHandler)
    );
  }

  postData<T>(url: string, value: T): Observable<T> {
    this.removeUndefinedProperties(value);
    return this.http.post<T>(this.apiEndpoint + url, value).pipe(
      catchError(this.errorHandler)
    );
  }

  putData<T>(url: string, value: T): Observable<T> {
    return this.http.put<T>(this.apiEndpoint + url, value).pipe(
      catchError(this.errorHandler)
    );
  }

  patchData<T>(url: string, value: T): Observable<T> {
    return this.http.patch<T>(this.apiEndpoint + url, value).pipe(
      catchError(this.errorHandler)
    );
  }

  deleteData<T>(url: string, param?: HttpParams): Observable<T> {
    return this.http.delete<T>(this.apiEndpoint + url, { params: param }).pipe(
      catchError(this.errorHandler)
    );
  }

  errorHandler(err: any): Observable<never> {
    console.error(err);
    return throwError(err);
  }

  private removeUndefinedProperties(obj: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
  }
}
