  
<div class="web-card mt-4 mt-md-0">
    <div class="main-content-top">
        <h3 class="title">Hi {{ currentCustomer.patientInfo.Patient_First_Name }},</h3>
        <p>Your contact lens prescription is still valid until {{formattedDate}}. Based on your previous order amount, you may be running low on your current supply, so we’ve prepared another order for you!</p>
        <div class="lab-dropdown-listing">
            <div class="row">
                <div class="col-md-6">
                    <div class="lab-card flex-wrap d-flex justify-content-between">
                        <div class="lab-content d-flex align-items-center">
                            <div class="lab-logo d-flex justify-content-center align-items-center">
                                <img src="{{ currentCustomer.Soft_CL_Lens_Left_Image ? currentCustomer.Soft_CL_Lens_Left_Image : 'https://assets.arrellio.com/uploads/1683576958503brokenlens.jpeg' }}" alt="" srcset="">
                            </div>
                            <div class="lab-info">
                                <h4 class="lab-name">{{currentCustomer.leftEyeData?.arrellioName}}</h4>
                                <p class="lab-subtext">{{currentCustomer.leftEyeData?.manufacturerName}}</p>
                            </div>
                        </div>
                        <div class="lab-dropdown">
                            <div class="dropdown">
                                <button class="btn btn-primary dropdown-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#leftEye">
                                    Left
                                    <img class="chevron-icon" src="./assets/images/lab-dropdown-icon.svg" alt="" srcset="">
                                </button>
                            </div>
                        </div>
                        <div class="eye-options collapse" id="leftEye">
                            <ul class="prescription d-flex flex-wrap">
                                <li class="each-info"><label>Eye</label><span>Left</span></li>
                                <li class="each-info"><label>Power</label><span>{{currentCustomer.Soft_CL_Lens_Left_Sphere?currentCustomer.Soft_CL_Lens_Left_Sphere:'--'}}</span></li>
                                <li class="each-info"><label>Cylinder</label><span>{{currentCustomer.Soft_CL_Lens_Left_Cylinder?currentCustomer.Soft_CL_Lens_Left_Cylinder:'--'}}</span></li>
                                <li class="each-info"><label>Axis</label><span>{{currentCustomer.Soft_CL_Lens_Left_Axis?currentCustomer.Soft_CL_Lens_Left_Axis:'--'}}</span></li>
                                <li class="each-info"><label>Add</label><span>{{currentCustomer.Soft_CL_Lens_Left_Add?currentCustomer.Soft_CL_Lens_Left_Add:'--'}}</span></li>
                                <li class="each-info"><label>Clear</label><span>Clear</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="lab-card flex-wrap d-flex justify-content-between">
                        <div class="lab-content d-flex align-items-center">
                            <div class="lab-logo d-flex justify-content-center align-items-center">
                                <img src="{{ currentCustomer.Soft_CL_Lens_Right_Image ? currentCustomer.Soft_CL_Lens_Right_Image : 'https://assets.arrellio.com/uploads/1683576958503brokenlens.jpeg' }}" alt="" srcset="">
                            </div>
                            <div class="lab-info">
                                <h4 class="lab-name">{{currentCustomer.rightEyeData?.arrellioName}}</h4>
                                <p class="lab-subtext">{{currentCustomer.rightEyeData?.manufacturerName}}</p>
                            </div>
                        </div>
                        <div class="lab-dropdown">
                            <div class="nav-item">
                                <button class="btn btn-primary dropdown-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#rightEye">
                                    right
                                    <img class="chevron-icon" src="./assets/images/lab-dropdown-icon.svg" alt="" srcset="">
                                </button>
                            </div>
                        </div>
                        <div class="eye-options collapse" id="rightEye">
                            <ul class="prescription d-flex flex-wrap">
                                <li class="each-info"><label>Eye</label><span>Right</span></li>
                                <li class="each-info"><label>Power</label><span>{{currentCustomer.Soft_CL_Lens_Right_Sphere?currentCustomer.Soft_CL_Lens_Right_Sphere:'--'}}</span></li>
                                <li class="each-info"><label>Cylinder</label><span>{{currentCustomer.Soft_CL_Lens_Right_Cylinder?currentCustomer.Soft_CL_Lens_Right_Cylinder:'--'}}</span></li>
                                <li class="each-info"><label>Axis</label><span>{{currentCustomer.Soft_CL_Lens_Right_Axis?currentCustomer.Soft_CL_Lens_Right_Axis:'--'}}</span></li>
                                <li class="each-info"><label>Add</label><span>{{currentCustomer.Soft_CL_Lens_Right_Add?currentCustomer.Soft_CL_Lens_Right_Add:'--'}}</span></li>
                                <li class="each-info"><label>Color</label><span>{{currentCustomer.Soft_CL_Lens_Right_Color}}</span></li>
                            </ul>
                        </div>
                     
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>