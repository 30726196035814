    <div class="option-card">
        <h4 class="option-title">Delivery Options</h4>
        <p  *ngIf="!currentCustomer.patientReorderSetting.shipToOffice" class="option-content">Please confirm your home delivery address – or edit it – and select the desired shipping speed of your contact lens order period.</p>
        <p *ngIf="currentCustomer.patientReorderSetting.shipToOffice" class="option-content">Where would you like your contacts delivered? We can send 
            them directly to you or have them shipped to our office instead.</p>
        <div class="delivery-content delivery-content-wrap">
            <div class="delivery-left-content">
                <div (click)="onSelectionDeliveryLocation()" class="delivery-top" [class.active-address]="deliveryLocation == 'home'">
                    <div class="title-content" >
                        <div class="address-checkbox" >
                          <input type="radio" class="form-check-radio" id="radiohome" name="optradio_home" value="home" [(ngModel)]="deliveryLocation" (change)="onSelectionDeliveryLocation()" />
                            <span *ngIf="currentCustomer.patientReorderSetting.shipToOffice" class="check-custom"></span>
                            <h6 class="delivery-title">Home Address</h6>
                            <p class="text-muted">
                                <span *ngIf="address1.length>0">{{address1}}</span>
                                <span *ngIf="address2.length>0">{{address2}}</span><span> {{city}},{{" "}}{{state}} {{zipcode}}</span></p>
                        </div>
                    </div>
                    <div class="delivery-edit home-edit-address" (click)="setDeliveryAddress('home')">
                        <i *ngIf="deliveryLocation !== 'home'">
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.999999L7 7L13 1" stroke="#5D9732" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        </i>
                        <button *ngIf="deliveryLocation == 'home'" data-bs-toggle="modal" data-bs-target="#saveModal" class="edit-btn-outline btn">Edit</button>
                    </div>
                </div>
                <div *ngIf="currentCustomer.patientReorderSetting.shipToOffice">
                    <div  class="delivery-top" [class.active-address]="deliveryLocation == 'office'">

                    <div class="title-content">
                        <div class="address-checkbox">
                            <input type="radio" class="form-check-radio" id="radioffc" name="optradio_office" value="office" [(ngModel)]="deliveryLocation" (change)="onSelectionDeliveryLocation()" />
                            <span class="check-custom"></span>
                            <h6 class="delivery-title">Office Address</h6>
                            <p class="text-muted"><span>{{currentCustomer.storesInfo.name}}</span><span>{{currentCustomer.storesInfo.city}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="delivery-edit" (click)="setDeliveryAddress('office')">
                        <i>
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.999999L7 7L13 1" stroke="#5D9732" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        </i>
                    </div>
                </div>
                </div>
            </div>
                <div  class="grey-wrap">
                    <h4 class="ship-speed-title">ship speed</h4>
                <div class="row">
                    <div class="col-12">
                      <div class="form-check-wrap">
                        <input type="radio" class="form-check-radio" id="radio1" name="optradio" value="standard" [(ngModel)]="selectedOption" (change)="onSelectionChange()" />
                        <span class="check-custom"></span>
                        <label *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughStander==0 || deliveryLocation == 'office'" class="form-check-label" for="radio3"><small>Standard · 3-5 Business Days</small><strong>FREE</strong></label>

                        <label *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughStander>0 && deliveryLocation !== 'office'" class="form-check-label" for="radio1"><small>Standard · 3-5 Business Days</small><strong>${{formatPrice(currentCustomer.patientReorderSetting.shippingPassthroughStander)}}</strong></label>
                      </div>
                      <div class="form-check-wrap" [class.disabledShip]="deliveryLocation == 'office'">
                        <input type="radio" class="form-check-radio" id="radio2" name="optradio" value="secondDay" [(ngModel)]="selectedOption" (change)="onSelectionChange()"/>
                        <span class="check-custom"></span>
               <label *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughTwoDay==0" class="form-check-label" for="radio2"><small>Second Day · 2 Business Days</small><strong>FREE</strong></label>

                        <label *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughTwoDay>0" class="form-check-label" for="radio2"><small>Second Day · 2 Business Days</small><strong>${{formatPrice(currentCustomer.patientReorderSetting.shippingPassthroughTwoDay)}}</strong></label>
                      </div>
                      <div class="form-check-wrap" [class.disabledShip]="deliveryLocation == 'office'">
                        <input type="radio" class="form-check-radio" id="radio3" name="optradio" value="nextDay" [(ngModel)]="selectedOption" (change)="onSelectionChange()" />
                        <span class="check-custom"></span>
                        <label *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughOneDay==0" class="form-check-label" for="radio3"><small>One Day · 1 Business Days</small><strong>FREE</strong></label>

                        <label  *ngIf="currentCustomer.patientReorderSetting.shippingPassthroughOneDay>0" class="form-check-label" for="radio3"><small>One Day · 1 Business Days</small><strong>${{formatPrice(currentCustomer.patientReorderSetting.shippingPassthroughOneDay)}}</strong></label>
                      </div>
                    </div>
                  </div>
            </div>
        <!-- </div> -->
        </div>
    </div>
    <div class="modal payment-success-modal" id="saveModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Modal Header -->
                <div  class="modal-header">
                    <h4 class="save-title text-center"> Edit Home Address </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                   
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="payment-success-content form-content">
                       <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-3"> 
                                    <label for="" class="form-label">Street 1</label>
                                    <input  type="text" class="form-control" placeholder="Street 1" [(ngModel)]="address1">
                                </div>
                                <div class="form-group mb-3">
                                    <label for="" class="form-label">Street 2</label>
                                    <input  type="text" class="form-control" placeholder="Street 2" [(ngModel)]="address2">
                                </div>
                                <div class="form-group mb-3">
                                    <label for="" class="form-label">City</label>
                                    <input  type="text" class="form-control" placeholder="City" [(ngModel)]="city">
                                </div>
                                <div class="form-group state-dropdown-group mb-3">
                                <label  class="form-label" for="state">State</label>
                                <img src="./assets/images/arrow-black.png"/>
                                <select class="form-control"  id="state" [(ngModel)]="state" name="state">
                                    <option *ngFor="let state of statesArray" [value]="state.abbr">{{ state.abbr }}</option>
                                </select>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="" class="form-label">Zip Code</label>
                                    <input  type="number" class="form-control" placeholder="Zip Code" [(ngModel)]="zipcode" (keyup)="limitToFiveDigits()">
                                </div>
                                <div class="form-group text-center">
                                    <button class="btn delivery-btn" (click)="onSelectionDeliveryLocation()" type="button"  data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
            </div>
        </div>
    </div>
