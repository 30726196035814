import { Component,Input,OnInit,EventEmitter ,Output} from '@angular/core';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit{
  @Input('currentCustomer') currentCustomer:any;
  @Output('changeInformation') changeInformation: EventEmitter<any> = new EventEmitter()

  email:string="";
  phoneNumber:any


  ngOnInit(): void {
   
  }

  usNumbermasking(e:any){
    e.target.value = this.phoneMask(e.target.value);
  }

  phoneMask (phone: any) {
    return phone.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{3})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }

  validateEmail(email:any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkEmailPhone(){
    var contactInfo={
       "email":this.email,
       "phoneNumber":this.phoneNumber
    }
    this.changeInformation.emit(contactInfo)
  }
}
